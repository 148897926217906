
// Theme colors
$endeavour:   #005AAA;
$clementine:  #F06400;

// Standardvariablen
$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #1C8033;
$teal:    #20c997;
$cyan:    #17a2b8;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

// scss-docs-start theme-color-variables
$primary: $endeavour;
$secondary: $clementine;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
);

$socialShareServiceColors: (
        facebook: #375797,
        whatsapp: #25D366,
        twitter: #579BD1,
        email: #FFC234
);
